<script setup lang="ts">
  import { type qBrickVideoConfig } from '../../../types/widgets/composites/qBrickVideoTypes'

  const { data } = defineProps<{
    data: qBrickVideoConfig
  }>()

  const playerElement = ref()

  const playerInternalInfo = reactive({
    imaPreroll:
      'https://pubads.g.doubleclick.net/gampad/ads?iu=/34405621/bp/woman-dk/preroll&description_url=https%3A%2F%2Fwoman.dk&tfcd=0&npa=0&sz=640x360&cmsid=[placeholder]&vid=[placeholder]&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=',
    qbrickAccount: useRuntimeConfig().public.QBRICK_ACCOUNT,
    theoLink: useRuntimeConfig().public.THEO_PLAYER_LINK,
  })

  const initQBrickScript = () => {
    // Initialize the player script
    if (
      !document.querySelector(`script[src="${playerInternalInfo.theoLink}"]`)
    ) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = playerInternalInfo.theoLink
      document.head.appendChild(script)
    }
  }

  const cleanUpQBrickScript = () => {
    // Remove the script so that it can be reinitialized.
    const script = document.head.querySelector(
      `script[src="${playerInternalInfo.theoLink}"]`
    )
    if (script) {
      script.parentNode?.removeChild(script)
    }
  }

  onNuxtReady(() => {
    initQBrickScript()
    playerElement.value.style.height = `${playerElement.value?.offsetWidth * 0.563}px`
  })

  onUnmounted(() => {
    cleanUpQBrickScript()
  })

  onMounted(() => {
    playerElement.value.style.height = `${playerElement.value?.offsetWidth * 0.563}px`
  })
</script>

<template>
  <div
    class="THEOplayer"
    ref="playerElement"
    :bonnier-player-media-id="!data?.is_playlist ? data?.id : null"
    :bonnier-player-playlist-id="data?.is_playlist ? data?.id : null"
    :account="playerInternalInfo?.qbrickAccount"
    :ima-preroll="playerInternalInfo?.imaPreroll"
    backdrop
    :replay="data?.loop"
    :autoplay="data?.autoplay"
    :muted="data?.autoplay || data?.is_muted"
  ></div>
</template>
